var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('q-intersection',{directives:[{name:"show",rawName:"v-show",value:(_vm.filter),expression:"filter"}],attrs:{"transition":"slide-down"}},[_c('q-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onFilterReports.apply(null, arguments)},"reset":_vm.clearFilter}},[(_vm.filter)?_c('q-card',{staticClass:"q-mb-sm",attrs:{"flat":"","bordered":""}},[_c('q-toolbar',[_c('q-btn',{attrs:{"flat":"","round":"","icon":"mdi-close"},on:{"click":function($event){_vm.filter = false}}})],1),_c('q-card-section',{staticClass:"q-pt-none row q-col-gutter-md"},[_c('div',{staticClass:"col-4"},[_c('date-input',{attrs:{"buttons":"","outlined":"","label":"Data dos cliques","v-model":_vm.params.date,"clearable":"","range":"","rules":[(val) => !!val || 'O campo data dos cliques é obrigatório']},on:{"update":(value) => (_vm.params.date = value)}})],1),_c('div',{staticClass:"col-4"},[_c('q-select',{attrs:{"options":[
                {
                  label: 'Por campanha',
                  value: 'campaign'
                },
                {
                  label: 'Por afiliado',
                  value: 'affiliate'
                },
                {
                  label: 'Por canal',
                  value: 'site'
                },
                {
                  label: 'Por dia',
                  value: 'day'
                },
                {
                  label: 'Por mês',
                  value: 'month'
                },
                {
                  label: 'Por ano',
                  value: 'year'
                }
              ],"label":"Modo de exibição","rules":[(val) => !!val || 'O campo de modo de exibição é obrigatório.'],"outlined":""},model:{value:(_vm.params.displayMode),callback:function ($$v) {_vm.$set(_vm.params, "displayMode", $$v)},expression:"params.displayMode"}})],1),_c('div',{staticClass:"col-4"},[_c('q-select',{attrs:{"use-input":"","input-debounce":"200","options":_vm.campaignsFiltered,"label":"Campanha","option-label":"name","option-value":"id","outlined":""},on:{"filter":_vm.filterComboCampaigns},model:{value:(_vm.params.campaign),callback:function ($$v) {_vm.$set(_vm.params, "campaign", $$v)},expression:"params.campaign"}})],1),_c('div',{staticClass:"col-4"},[_c('q-input',{attrs:{"use-input":"","input-debounce":"200","label":"Canal ID","outlined":"","type":"number"},model:{value:(_vm.params.siteId),callback:function ($$v) {_vm.$set(_vm.params, "siteId", $$v)},expression:"params.siteId"}})],1),_c('div',{staticClass:"col-4"},[_c('q-input',{attrs:{"use-input":"","label":"Nome do canal","outlined":""},model:{value:(_vm.params.siteName),callback:function ($$v) {_vm.$set(_vm.params, "siteName", $$v)},expression:"params.siteName"}})],1),_c('div',{staticClass:"col-4"},[_c('q-input',{attrs:{"use-input":"","label":"Nome do Afiliado","outlined":""},model:{value:(_vm.params.affiliateName),callback:function ($$v) {_vm.$set(_vm.params, "affiliateName", $$v)},expression:"params.affiliateName"}})],1),_c('div',{staticClass:"col-12 text-right"},[_c('q-btn',{staticClass:"q-px-md q-mx-md",attrs:{"color":"primary","label":"Limpar","no-caps":"","outline":"","type":"reset"}}),_c('q-btn',{staticClass:"q-px-md",attrs:{"color":"positive","label":"Buscar","no-caps":"","unelevated":"","type":"submit"}})],1)])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }