<template>
  <q-table
    flat
    bordered
    :data="reports"
    row-key="id"
    :columns="columns"
    :pagination="pagination"
    :visible-columns="visibleColumns"
  >
    <template v-slot:top-right>
      <q-btn
        outline
        color="grey-3"
        size="12px"
        text-color="grey-8"
        icon-right="archive"
        class="q-mr-sm q-py-xs"
        no-caps
        @click="exportClickReport()"
        unelevated
      >
        <q-tooltip> Exportar como CSV</q-tooltip>
      </q-btn>
      <q-select
        v-model="visibleColumns"
        multiple
        outlined
        dense
        options-dense
        display-value="Personalizar Colunas"
        emit-value
        map-options
        :options="columns"
        option-value="name"
        options-cover
        style="min-width: 150px"
      >
        <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
          <q-item v-bind="itemProps" v-on="itemEvents">
            <q-item-section>
              <q-item-label v-html="opt.label"></q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-toggle :value="selected" @input="toggleOption(opt)" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </template>

    <template v-slot:body-cell-ctr="props">
      <q-td :props="props">
        {{ `${props.row.ctr}%` }}
      </q-td>
    </template>

    <template v-slot:body-cell-scr="props">
      <q-td :props="props">
        {{ `${props.row.scr}%` }}
      </q-td>
    </template>

    <template v-slot:body-cell-lcr="props">
      <q-td :props="props">
        {{ `${props.row.lcr}%` }}
      </q-td>
    </template>

    <template v-slot:body-cell-transaction_amount_pending="props">
      <q-td :props="props">
        {{ `R$ ${props.row.transaction_amount_pending}` }}
      </q-td>
    </template>

    <template v-slot:body-cell-transaction_amount_approved="props">
      <q-td :props="props">
        {{ `R$ ${props.row.transaction_amount_approved}` }}
      </q-td>
    </template>

    <template v-slot:body-cell-margin_cpc_pending="props">
      <q-td :props="props">
        {{ `R$ ${props.row.margin_cpc_pending}` }}
      </q-td>
    </template>

    <template v-slot:body-cell-margin_cpc_approved="props">
      <q-td :props="props">
        {{ `R$ ${props.row.margin_cpc_approved}` }}
      </q-td>
    </template>

    <template v-slot:body-cell-total_pending="props">
      <q-td :props="props">
        {{ `R$ ${props.row.total_pending}` }}
      </q-td>
    </template>

    <template v-slot:body-cell-total_approved="props">
      <q-td :props="props">
        {{ `R$ ${props.row.total_approved}` }}
      </q-td>
    </template>

    <template v-slot:bottom>
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>

<script>
import ReportMixin from '../../../mixins/ReportMixin';
// import PaginationTableReports from "../PaginationTableReports";

export default {
  //   components: { PaginationTableReports },
  name: 'AdvertiserListClickReports',
  mixins: [ReportMixin],

  data() {
    return {
      visibleColumns: null,
      columns: [
        {
          name: 'indice',
          label: 'Índice',
          align: 'left',
          field: 'indice',
          sortable: false
        },
        {
          name: 'id_site',
          label: 'ID Canal',
          align: 'left',
          field: 'id_site',
          sortable: false
        },
        {
          name: 'impressions',
          label: 'Impressões',
          align: 'left',
          field: 'impressions',
          sortable: false
        },
        {
          name: 'total_clicks',
          label: 'Cliques totais',
          align: 'left',
          field: 'total_clicks',
          sortable: false
        },
        {
          name: 'unique_clicks',
          label: 'Cliques únicos',
          align: 'left',
          field: 'unique_clicks',
          sortable: false
        },
        {
          name: 'ctr',
          label: 'CTR %',
          align: 'left',
          field: 'ctr',
          sortable: false
        },
        {
          name: 'pending_leads',
          label: 'Cadastros Pendentes',
          align: 'left',
          field: 'pending_leads',
          sortable: false
        },
        {
          name: 'approved_leads',
          label: 'Cadastros Aprovados',
          align: 'left',
          field: 'approved_leads',
          sortable: false
        },
        {
          name: 'lcr',
          label: 'LCR %',
          align: 'left',
          field: 'lcr',
          sortable: false
        },
        {
          name: 'pending_sales',
          label: 'Vendas Pendentes',
          align: 'left',
          field: 'pending_sales',
          sortable: false
        },
        {
          name: 'approved_sales',
          label: 'Vendas Aprovadas',
          align: 'left',
          field: 'approved_sales',
          sortable: false
        },
        {
          name: 'scr',
          label: 'SCR %',
          align: 'left',
          field: 'scr',
          sortable: false
        },
        {
          name: 'total_pending',
          label: 'Valor Total Pendente',
          align: 'left',
          field: 'total_pending',
          sortable: false
        },
        {
          name: 'total_approved',
          label: 'Valor Total Aprovado',
          align: 'left',
          field: 'total_approved',
          sortable: false
        }
      ],
      pagination: {
        sortBy: 'created_at',
        descending: false,
        rowsPerPage: 10
      }
    };
  },
  methods: {
    async exportClickReport() {
      this.onLoading(false);
      try {
        const { data, status } = await this.$http.get(
          'redirect?app=report&path=/api/report/advertiser_click_report_csv?' + this.filterReports
        );
        if (status === 200 || status === 201) {
          console.log(data);
          this.exportTableCsv(data, this.columns, 'click-reports');
          this.successNotify('Exportado com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  watch: {
    currentPage() {
      this.getConsolidatedClickReportAdvertiser(
        `%26page=${this.currentPage}`,
        `%26per_page=${this.pagination.rowsPerPage}`
      );
    },
    filterReports() {
      if (this.filterReports && this.filterReports.includes('filter=site'))
        this.visibleColumns = this.columns.map((column) => column.name);
      else this.visibleColumns = this.columns.filter((item) => item.name != 'id_site').map((column) => column.name);
    }
  },

  computed: {
    show() {
      return typeof this.reports === 'undefined' ? false : false;
    },

    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  created() {
    this.ActionSetFilterReports();
    this.ActionSetReports();
    this.visibleColumns = this.columns.filter((item) => item.name != 'id_affiliate').map((column) => column.name);
  }
};
</script>
